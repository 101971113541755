<template>
  <div class="home">
    <el-container>
      <el-header class="header">
        <div class="left">
          农贸后台管理系统
        </div>
        <div class="right" @click="out_login">退出</div>
      </el-header>
      <el-container>
        <el-aside width="210px" style="height:100%;">
          <el-menu
            unique-opened
            collapse-transition
            :default-active="defaultActive"
            class="el-menu-vertical-demo"
            style="height:100%; overflow:scroll;"
            background-color="#545c64"
            active-text-color="#ffd04b"
            text-color="#fff"
            router
          >
            <!-- 市场管理 -->
            <el-submenu index="1">
              <template slot="title">
                <span>市场管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/home/market_list">市场列表</el-menu-item>
                <el-menu-item index="/home/MarketDivisionManagement"
                  >市场分区管理</el-menu-item
                >
                <el-menu-item index="/home/MarketDivisionManagementPeople"
                  >市场管理人员管理</el-menu-item
                >
                <el-menu-item index="/marketInformation"
                  >市场设备人员信息</el-menu-item
                >
                <el-menu-item v-if="makeid === 0" index="/ranking"
                  >市场排名</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <!-- 商户诚信管理子系统 -->
            <el-submenu index="2">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>商户诚信管理子系统</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/home/evaluateList">评价管理</el-menu-item>
                <el-menu-item index="/home/evaluateList2"
                  >投诉管理列表</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <!-- 摊位管理 -->
            <el-submenu index="3">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>摊位管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/home/stallList"
                  >市场摊位管理</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <!-- 经营户管理 -->
            <el-submenu index="4">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>经营户管理</span>
              </template>

              <el-menu-item-group>
                <el-menu-item index="/home/addManage"
                  >经营户基本信息管理</el-menu-item
                >
                <el-menu-item index="/home/managetabal"
                  >经营户经营信息管理</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <!--商户管理  -->
            <el-submenu index="5">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>商品管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/home/productList"
                  >商品分类管理</el-menu-item
                >
                <el-menu-item index="/home/agencyList">经营户商品</el-menu-item>

                <el-menu-item index="/home/commodityLists"
                  >经营户商品分类管理</el-menu-item
                >
                <el-menu-item index="/home/commodityList">商品库</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- 采购信息 -->
            <el-submenu index="6">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>采购信息</span>
              </template>
              <el-menu-item-group>
                <!-- <el-menu-item index="/home/purchaseList">采购信息</el-menu-item> -->
                <el-menu-item index="/home/supplier">供应商列表</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- 索证索票 -->
            <el-submenu index="7">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>索证索票</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/home/backstageList">后台</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- 交易信息管理 -->
            <el-menu-item index="/home/orderFormList"
              >交易信息管理</el-menu-item
            >
            <!-- 检测信息管理 -->
            <el-submenu index="8" v-if="makeid === 0">
              <template slot="title">
                <span>检测信息管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/home/testingList">检测信息</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- 设备管理 -->
            <el-submenu index="9" v-if="makeid === 0">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>设备管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/home/electronicList"
                  >设备管理</el-menu-item
                >
                <el-menu-item index="/home/electronicLists"
                  >设备厂商</el-menu-item
                >
                <el-menu-item index="/home/deviceMaintain"
                  >设备维护</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="10" v-if="makeid === 0">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>市场巡查管理</span>
              </template>
              <el-menu-item index="/InspectUnit/list"
                >巡查单位管理</el-menu-item
              >
              <el-menu-item index="/personnel/list">巡查人员管理</el-menu-item>
              <el-menu-item index="/Patrolproject/list"
                >巡查项目管理</el-menu-item
              >
              <el-menu-item index="/InspectionRecord/list"
                >巡查记录</el-menu-item
              >
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main class="view">
          <!-- <vTags></vTags> -->
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { Authorizations } from '../../api/home.js'
import vTags from './Tags'
export default {
  name: 'home',
  components: {
    vTags
  },
  data () {
    return {
      defaultActive: '',
      name: '',
      makeid: ''
    }
  },
  watch: {
    $route () {
      this.setCurrentRoute()
    }
  },
  created () {
    this.setCurrentRoute()
    this.$store.dispatch('order/getUserInfo')
    this.makeid = this.$store.state.order.userInfo.market_id
    // console.log(this.$store.state.order.userInfo)
    // trans.$on("tags", msg => {
    //   let arr = [];
    //   for (let i = 0, len = msg.length; i < len; i++) {
    //     msg[i].name && arr.push(msg[i].name);
    //   }
    //   this.tagList = arr;
    //   console.log(this.tagList);
    // });
  },
  methods: {
    setCurrentRoute () {
      this.defaultActive = this.$route.path
    },
    async out_login () {
      const res = await Authorizations()
      if (res.code === 200) {
        this.$router.push({ path: '/login' })
        this.$message({
          message: res.data,
          type: 'success'
        })
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #545c64;
    color: #fff;
    .left {
      font-size: 26px;
      font-weight: bold;
    }
    .right {
      cursor: pointer;
    }
  }
  .el-container {
    height: calc(100vh - 60px);
  }
  .view {
    width: 100%;
    height: calc(100vh - 60px);
  }
}
</style>
